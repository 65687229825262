import { Box, Card, CardContent, Typography } from '@mui/joy';
import React from 'react';

const DistanceWidget = (props: {distance: string}) => {
  const {distance} = props
  return (
    <Card variant="plain" sx={{flex: 1, marginLeft: 1, height: 250, background: "#87CEEB"}}>
    <CardContent>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
        <Typography level="h1">{distance}</Typography>
      </Box>
    </CardContent>
  </Card>
  );
};

export default DistanceWidget;