import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Container, CssBaseline, Grid, Typography } from '@mui/joy';
import LicensePlate from './components/licensePlate/LicensePlate';
import StatusWidget from './components/StatusWidget';
import DistanceWidget from './components/DistanceWidget';
import LogWidget from './components/LogWidget';

export type Data = {
  status: string,
  distance: string,
  numberplate: string
  log: LogEntry[]
}

export type LogEntry = string

const App = () => {

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const localhost = params.get('localhost');

  const [data, setData] = useState<Data>({status: "...", distance: "...", numberplate: "...", log: []});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const host = localhost === null ? "192.168.178.57" : "127.0.0.1"
        const response = await fetch(`http://${host}:5000/data`);
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <CssBaseline/>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} height="100%" >
        <Box display="flex" justifyContent={"space-between"}>
          <Box display={"flex"} padding={1}>
            <img width={960} height={540} src="http://192.168.178.57:7123/stream.mjpg" style={{borderRadius: "5px"}}></img>
          </Box>
          <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} padding={1} width="100%" gap={1}>
            <Box display="flex" justifyContent={"space-between"}>
              <StatusWidget status={data.status}/>
              <DistanceWidget distance={data.distance}/>
            </Box>
            
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
              <LicensePlate countryCode='D' plateId={data.numberplate} height={270} />
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} flexGrow={1} sx={{overflowY: "hidden"}}>
          <LogWidget log={data.log}/>
        </Box>
      </Box>
      
    </>
  );
};

export default App;