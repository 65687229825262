import { Box, Card, CardContent, Typography } from '@mui/joy';
import React from 'react';

const COLORS = {
  "free": "green",
  "leaving": "green",
  "parking": "orange",
  "loading": "red"
}

const StatusWidget = (props: {status: string}) => {
  const {status} = props

  //@ts-ignore
  const color = COLORS[status]??"green"

  return (
    <Card variant="plain" sx={{flex: 1, marginRight: 1, background: color}}>
    <CardContent>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"100%"}>
        <Typography level="h1">{status}</Typography>
      </Box>
    </CardContent>
  </Card>
  );
};

export default StatusWidget;