import { Card, CardContent, Typography } from '@mui/joy';
import React from 'react';
import { LogEntry } from '../App';

export type LogWidgetProps = {
  log: LogEntry[]
}

const LogWidget = (props: LogWidgetProps) => {
  return (
    <Card variant="plain" sx={{width: "100%", marginLeft: 1, marginRight: 1, marginBottom: 1, overflowY: "scroll"}}>
    <CardContent>
      {props.log.map(entry => <Typography level="h1">{entry}</Typography>)}
    </CardContent>
  </Card>
  );
};

export default LogWidget;